<template>
  <div class="flex flex-col">
    <div
      class="flex justify-between items-start h-15-screen 2xl:h-10-screen p-6"
    >
      <page-title
        :title="$t('PRESETS.TITLE')"
        :description="
          isLendflowUser
            ? $t('PRESETS.DESCRIPTION_LENDFLOW')
            : $t('PRESETS.DESCRIPTION_CLIENT')
        "
      />
      <div class="flex space-x-4">
        <search-input
          no-margin
          no-padding
          v-model="filters.search"
          trim-value
        />

        <primary-button
          @click.prevent="showPresetModal = true"
          data-cy="button-add-preset"
        >
          {{ $t("PRESETS.ADD_PRESET") }}
        </primary-button>
      </div>
    </div>

    <lf-table
      class="freeze-first freeze-border-first px-6 pb-4"
      data-cy="presets-table"
      header-sticky
      :class="tableClassList"
      :columns="presetsColumns"
      :pagination-absolute="false"
      :is-table-loading="loading"
      :data="all.data"
      :metadata="all.meta"
      @change-page="changePage($event)"
      @change-items-per-page="changePerPage($event)"
    />
  </div>
  <preset-modal
    v-if="showPresetModal"
    :close="() => (showPresetModal = false)"
  />
</template>

<script lang="ts">
import { ref, watch, computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";

import { usePromiseWrapper } from "@/hooks/common";
import { useAuth } from "@/hooks/auth";
import { DEFAULT_PER_PAGE } from "@/helpers/constants";
import { commaSeparatedStringFormatter } from "@/helpers/formatting";
import type { IDealsColumn } from "@/models/applications";
import type { IWorkflow } from "@/models/workflows";
import type { IClient } from "@/models/clients";
import type { PermissionsPresetFilter } from "@/models/permissionsPreset";
import usePermissionsPresetStore from "@/stores/permissionsPreset";
</script>

<script setup lang="ts">
import PageTitle from "@/components/ui/PageTitle.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";
import LfTable from "@/components/ui/table/LfTable.vue";
import PresetActionMenu from "@/components/presets/PresetActionMenu.vue";
import PresetNameColumn from "@/components/presets/PresetNameColumn.vue";
import PresetCategoriesColumn from "@/components/presets/PresetCategoriesColumn.vue";
import PresetModal from "@/components/presets/presetModal/PresetModal.vue";

defineProps({
  tableClassList: {
    type: [String, Object] as PropType<string | Record<string, boolean>>,
    default: "h-85-screen 2xl:h-90-screen"
  }
});

const { t } = useI18n();
const { isLendflowUser } = useAuth();
const permissionsPresetStore = usePermissionsPresetStore();
const { all } = storeToRefs(permissionsPresetStore);
const { getAll } = permissionsPresetStore;

const filters = ref<
  Omit<
    PermissionsPresetFilter,
    "assigned_workflow_template_ids" | "assigned_client_ids"
  >
>({
  page: 1,
  per_page: DEFAULT_PER_PAGE,
  search: ""
});
const { fetchWrapper, loading, changePage, changePerPage } = usePromiseWrapper(
  getAll,
  { filters }
);

const showPresetModal = ref(false);

const presetsColumns = computed(
  (): Array<IDealsColumn> =>
    compact([
      {
        key: "name",
        label: t("PRESETS.PRESET_NAME"),
        component: PresetNameColumn
      },
      {
        key: "",
        label: t("PRESETS.CATEGORIES"),
        component: PresetCategoriesColumn
      },
      {
        key: "assigned_workflow_templates",
        label: t("COMMON.TEMPLATE", 2),
        formatter: (templates: Array<IWorkflow>) =>
          commaSeparatedStringFormatter(templates, "name")
      },
      isLendflowUser && {
        key: "assigned_clients",
        label: t("PRESETS.CLIENTS"),
        formatter: (clients: Array<IClient>) =>
          commaSeparatedStringFormatter(clients, "name")
      },
      {
        key: "actions",
        label: "",
        component: PresetActionMenu
      }
    ])
);

watch(
  () => cloneDeep(filters.value),
  (newFilters, oldFilters) => {
    if (oldFilters?.page === newFilters.page) {
      newFilters.page = 1;
    }
    fetchWrapper(newFilters);
  },
  { immediate: true }
);
</script>
